import WalletForm from "../components/forms/wallet-form";
import { useState } from "react";

let loadingEvm = false;
let loadingCardano = false;

const HomeContainer = () => {
  const [errors, setErrors] = useState("");
  const [status, setStatus] = useState("");

  const [evmErrors, setEvmErrors] = useState("");
  const [evmStatus, setEvmStatus] = useState("");

  const [addresses, setAddresses] = useState({ cardano: "", evm: "" });
  const [loading, setLoading] = useState(false);

  const requestTokens = async () => {
    if (!addresses.cardano && !addresses.evm) {
      setErrors("Please enter at least one address");
      setStatus("");
      return;
    }

    // @ts-ignore
    if (addresses.evm) {
      setLoading(true);
      loadingEvm = true;
      (async () => {
        const response = await fetch(
          process.env.REACT_APP_FAUCET_URL + `?address=${addresses.evm}`,
          { method: "POST" }
        );
        const data = await response.json();
        if ((response as any).status !== 200) {
          if (response.status === 429) {
            setEvmErrors(
              "You can only request once per hour. Please try again later."
            );
          } else {
            setEvmErrors(
              "Something went wrong with the EVM faucet. Please ensure your address is a testnet address and try again."
            );
          }
          setEvmStatus("");
        } else {
          if (data.warnings) {
            setEvmStatus("");
            setEvmErrors(data.warnings);
          } else {
            setEvmStatus("YOUR TOKENS HAVE BEEN SENT");
            setEvmErrors("");
          }
        }
        loadingEvm = false;
        if (!loadingCardano) setLoading(false);
      })();
    }
    if (addresses.cardano) {
      setLoading(true);
      loadingCardano = true;
      (async () => {
        const response = await fetch(
          process.env.REACT_APP_FAUCET_URL_CARDANO as string,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ mtUserAddress: addresses.cardano }),
          }
        );
        const data = await response.json();
        if ((response as any).status !== 200) {
          setErrors(
            "Something went wrong with the Cardano faucet. Please ensure your address is a testnet address and try again."
          );
          setStatus("");
        } else {
          if (data.warnings) {
            setStatus("");
            setErrors(data.warnings);
          } else {
            setStatus("YOUR TOKENS HAVE BEEN SENT");
            setErrors("");
          }
        }
        loadingCardano = false;
        if (!loadingEvm) setLoading(false);
      })();
    }
  };

  const setAddressesWrapper = (data: { cardano: string; evm: string }) => {
    setErrors("");
    setStatus("");
    setEvmErrors("");
    setEvmStatus("");
    setAddresses(data);
  };

  return (
    <div className="container">
      <h1>MELD Testnet Faucet</h1>
      <WalletForm
        loading={loading}
        status={status}
        errors={errors}
        evmStatus={evmStatus}
        evmErrors={evmErrors}
        requestTokens={requestTokens}
        setAddresses={setAddressesWrapper}
        addresses={addresses}
      />
    </div>
  );
};

export default HomeContainer;
