const WalletForm = ({
  requestTokens,
  errors,
  status,
  evmErrors,
  evmStatus,
  loading,
  addresses,
  setAddresses,
}: {
  requestTokens: Function;
  errors: string;
  status: string;
  evmErrors: string;
  evmStatus: string;
  loading: boolean;
  setAddresses: (data: { cardano: string; evm: string }) => void;
  addresses: { cardano: string; evm: string };
}) => {
  const multipleAddresses = addresses.cardano && addresses.evm;

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          marginTop: 30,
        }}
      >
        <input
          onChange={(e) =>
            setAddresses({
              evm: e.target.value,
              cardano: addresses.cardano,
            })
          }
          value={addresses.evm}
          placeholder={"EVM wallet address (optional)"}
        />
        <input
          onChange={(e) =>
            setAddresses({
              evm: addresses.evm,
              cardano: e.target.value,
            })
          }
          value={addresses.cardano}
          placeholder={"Cardano wallet address (optional)"}
        />
      </div>
      {!loading && (evmErrors || errors || evmStatus || status) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            marginTop: multipleAddresses ? 30 : 0,
            width: "100%",
            paddingTop: !multipleAddresses ? 30 : 0,
          }}
        >
          {(evmErrors || evmStatus) && (
            <div>
              {multipleAddresses && <b>EVM</b>}
              {evmErrors && (
                <div>
                  <p
                    style={{ textAlign: multipleAddresses ? "left" : "center" }}
                  >
                    {evmErrors}
                  </p>
                </div>
              )}
              {!evmErrors && evmStatus && (
                <>
                  <h3
                    style={{
                      fontWeight: "bold",
                      color: "#08ff04",
                      margin: 0,
                      textAlign: multipleAddresses ? "left" : "center",
                    }}
                  >
                    {evmStatus}
                  </h3>
                </>
              )}
            </div>
          )}

          {(status || errors) && (
            <div>
              {multipleAddresses && <b>Cardano</b>}
              {errors && (
                <div>
                  <p
                    style={{ textAlign: multipleAddresses ? "left" : "center" }}
                    dangerouslySetInnerHTML={{
                      __html: errors
                        .toString()
                        .replaceAll("[Mint all tokens] ", "")
                        .replaceAll("\n", "<br/>"),
                    }}
                  />
                </div>
              )}
              {!errors && status && (
                <>
                  <h3
                    style={{
                      fontWeight: "bold",
                      color: "#08ff04",
                      margin: 0,
                      textAlign: multipleAddresses ? "left" : "center",
                    }}
                  >
                    {status}
                  </h3>
                </>
              )}
            </div>
          )}
        </div>
      )}

      <button
        style={{
          marginTop: 30,
        }}
        disabled={loading}
        onClick={() => requestTokens()}
      >
        {loading ? "LOADING" : "GIVE ME TOKENS"}
      </button>
    </>
  );
};

export default WalletForm;
