import React from "react";

import LayoutContainer from "./containers/layout";
import HomeContainer from "./containers/home";
import "./index.css";

function App() {
  return (
    <LayoutContainer>
      <HomeContainer />
    </LayoutContainer>
  );
}

export default App;
